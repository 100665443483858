import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.less';
import {get,post} from '@/axios';
import '../src/utils/rem'
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.use(Vant);
Vue.config.productionTip = false

function makePositive(number) {
  return Math.abs(number);
}
Vue.filter('formatCurrency', function(value) {
  console.log('222333')
  // value=-8000
  if (!value) return '0.00';
 
var value3=Math.abs(value)
  var value2=makePositive(value3)/100
  var intPart = Number(value3).toFixed(0).slice(0, -2); // 获取整数部分
  
  let zuihou=getLastTwoDigits(value3)
  if(zuihou<10){
    zuihou='.0'+zuihou
  }else{
    zuihou='.'+zuihou
  }
  var fu
  if(value>0){
    fu=''
  }else{
    fu='-'
  }
  
  var intLen = intPart.length; // 整数部分的长度
  var result = '';
  
    while (intLen > 3) {
      result = ',' + intPart.substring(intLen - 3, intLen) + result;
      intLen -= 3;
    }

  var qian=intPart.substring(0, intLen) + result
  if(qian==''){
    qian=0
  }
  let mo=fu+qian+zuihou
  // if( Number(mo).toFixed(2)<1){
  //   mo = 0+mo
  // }
  return mo
});
//获取最后2位数
function getLastTwoDigits(number) {
  return number % 100;
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
