<template>
  <div class="home">
    <faceBook></faceBook>
    <router-view />
    <van-tabbar v-model="active"  @change="onTabChange"  z-index="99">
      <van-tabbar-item >
        <span>หน้าแรก</span>
        <template #icon="props">
          <img :src="props.active ? icon.active1 : icon.icon1" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item >
        <span>ฝากเงิน</span>
        <template #icon="props">
          <img :src="props.active ? icon.active2 : icon.icon2" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item >
        <span>บริการลูกค้า</span>
        <template #icon="props">
          <img :src="props.active ? icon.active3 : icon.icon3" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item >
        <span>ศูนย์ส่วนตัว</span>
        <template #icon="props">
          <img :src="props.active ? icon.active4 : icon.icon4" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <van-popup v-model="show" closeable position="bottom" :style="{ height: '50%' ,    background: 'black'}">
            <div class="register">
                <div class="register-top"><img src="../assets/home/register.png" alt=""></div>
                <div class="register-con">
                    <div class="register-con-text">กรุณาสมัครสมาชิกหรือเข้าสู่ระบบก่อน</div>
                    <div class="btn">
                        <div class="ll" @click="goto('login')">เข้าสู่ระบบ</div>
                        <div class="rr" @click="goto('register')">สมัครสมาชิก</div>
                    </div>
                </div>
            </div>
        </van-popup>
  </div>
</template>

<script>
// @ is an alias to /src
import {setKey} from '../utils/utils'
import faceBook from '../views/facebook/index.vue'
export default {
  name: 'HomeView',
  components: {
    faceBook
  },
  data() {
    return {
      // active: localStorage.getItem('active'),
      show: false,
      shouldNavigate: true,
      userId: Number(localStorage.getItem('userId')) || '',
      icon: {
        active1: require('../assets/home/icon1.png'),
        icon1: require('../assets/home/icon1-.png'),
        active2: require('../assets/home/icon2.png'),
        icon2: require('../assets/home/icon2-.png'),
        active3: require('../assets/home/icon3.png'),
        icon3: require('../assets/home/icon3-.png'),
        active4: require('../assets/home/icon4.png'),
        icon4: require('../assets/home/icon4-.png'),
      }
    }
  },
  computed:{
    active:{
      get(){
        return this.$route.meta.activeNum
      },
      set(){

      }
    }
  },
  created(){
    setKey()

  },
  methods:{
    goto(url) {
            this.$router.push({
                path: url,
            })
        },
    onTabChange(index) {
      if (Number(localStorage.getItem('userId')) == '') {
          //没登录
          
          if(index==1||index==3){
            this.show=true
            this.active=0
          }
          if(index==0){
            this.$router.push('/')
          }
          if(index==2){
            this.$router.push('/customer')
          }
      }else{
        switch(index){
          case 0:
            this.$router.push('/')
            break;
          case 1:
            this.$router.push('/recharge')
            break;
          case 2:
            this.$router.push('/customer')
            break;
          case 3:
            this.$router.push('/my')
            break;
        }
      }
    },
  }
 
}
</script>
<style lang="less" scoped>
  .register {
    .register-top {
        img {
            width: 100%;
        }
    }

    .register-con {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #000000;

        .register-con-text {
            font-weight: bold;
            font-size: 1.3rem;
            color: #C1CEF1;
            margin: 3rem;
        }

        .btn {
            display: flex;
            align-items: center;

            .ll {
                width: 7.5rem;
                height: 3rem;
                // background-color: #EF8C0B;
                border: 1px solid #C1CEF1;
                border-radius: .3rem;
                text-align: center;
                line-height: 3rem;
                font-size: 1rem;
                margin-right: 2rem;
            }

            .rr {
                width: 7.5rem;
                height: 3rem;
                // border: 1px solid #C1CEF1;
                background-color: #BD0E23;
                border-radius: .3rem;
                text-align: center;
                line-height: 3rem;
                font-size: 1rem;
            }
        }
    }
}
</style>