import axios from "axios";
import { Toast } from 'vant';
// axios.defaults.baseURL = 'https://system.p9w.me/'
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers['Content-Type'] = 'application/json';
// axios.defaults.headers['Authorization'] = localStorage.getItem('token') || 'Bearer'
// Add a request interceptor
// axios.interceptors.request.use(function (config) {
// 	// Do something before request is sent
// 	// config.headers['Authorization'] = localStorage.getItem('token') || 'Bearer'
// 	return config;
// }, function (error) {
// 	// Do something with request error
// 	Promise.reject(error);
// });
axios.interceptors.request.use(config => {
	// 可以在这里添加请求前的处理逻辑
	return config;
}, error => {
	// 对请求错误做些什么
	console.error(error);
	return
});

// Add a response interceptor
// axios.interceptors.response.use(function (response) {
// 	console.log('333')
// 	let statusCode = response.data.code
// 	if(statusCode=='A0204'){
// 		return false
// 	}
// 	if (statusCode == '00000') {
// 		return response.data
// 	} else {
// 		let status = response.data.status
// 		if (status == 200) {
// 			return response.data
// 		} else {
// 			Toast(response.data.msg)
// 			return
// 		}
// 	}
// }, function () {
// 	return Promise.reject("请求失败请重试")
// })

// 响应拦截器
axios.interceptors.response.use(response => {
	console.log('23332222')
	// 对响应数据做点什么
	let statusCode = response.data.code
	if (statusCode == 'A0204') {
		let login = JSON.parse(response.config.data)
		if (login.serviceName == "zacc_login") {
			// Toast(response.data.msg)
			getToast(statusCode)
		}
		return false
	}
	if (statusCode == '00000') {
		return response.data
	} else {
		let status = response.data.status
		if (status == 200) {
			return response.data
		} else {
			getToast(statusCode)
			// Toast(response.data.msg)
			return
		}
	}
}, error => {
	if (error.response) {
		// 请求已发出，服务器用状态码响应
		console.error('1 ', error.response);
	} else if (error.request) {
		// 请求已发出但没有收到响应
		// 可能是网络问题，请求被中断
		Toast('การเชื่อมต่อหมดเวลา')
		console.error('2', error.request);
	} else {
		// 在设置发送请求的过程中发生错误
		console.error('3', error.message);
	}
	// 可以在这里处理错误响应
	return
});

export function get(url, params) {
	return new Promise((resolve, reject) => {
		axios.get(url, {
			params: params
		}).then(res => {
			resolve(res);
		}).catch(err => {
			reject(err.data)
		})
	});
}
export function post(url, params) {
	return new Promise((resolve, reject) => {
		axios.post(url, params)
			.then(res => {
				resolve(res);
			})
			.catch(err => {
				reject(err.data)
			})
	});
}
// export default axios


function getToast(msg) {
	switch (msg) {
		case 'A0001':
			Toast('ผู้ใช้ยังไม่ได้ผูกหมายเลขโทรศัพท์')
			break;
		case 'A0002':
			Toast('ต้องเติมเงิน')
			break;
		case 'A0003':
			Toast('จำนวนเงินเติมไม่เพียงพอ')
			break;
		case 'A0204':
			Toast('ไม่พบผู้ใช้')
			break;
		case 'D0001':
			Toast('ไม่ตรงตามเงื่อนไข')
			break;
		case 'D0002':
			Toast('รับไม่สำเร็จ')
			break;
		case 'D0003':
			Toast('ไม่พบแพลตฟอร์มข้อความ')
			break;
		case 'D0004':
			Toast('รหัสยืนยันไม่ถูกต้อง')
			break;
		case 'D0005':
			Toast('ส่งรหัสยืนยันไม่สำเร็จ')
			break;
		case 'D0006':
			Toast('ส่งข้อความบ่อยเกินไป')
			break;
		case 'D0007':
			Toast('ส่งข้อความบ่อยเกินไป กรุณาลองอีกครั้งหลังจาก 5 นาที')
			break;
		case 'D0008':
			Toast('รหัสยืนยันหมดอายุ')
			break;
		case 'D0009':
			Toast('รหัสยืนยันถูกใช้แล้ว')
			break;
		case 'D0010':
			Toast('คลิกเร็วเกินไป')
			break;
		case 'D0011':
			Toast('ไม่เป็นไปตามเงื่อนไขถอนเงิน')
			break;
		case 'D0012':
			Toast('รับโบนัสซ้ำ')
			break;
		case 'D0013':
			Toast('ไม่สามารถรับโบนัสได้')
			break;
		case 'D0014':
			Toast('วิธีการนี้ไม่รองรับ')
			break;
		case 'D0015':
			Toast('ระบบกำลังบำรุงรักษา กรุณาลองใหม่ภายหลัง')
			break;
		case 'D0016':
			Toast('ชื่อผู้ใช้ไม่ถูกต้อง')
			break;
		case 'D0017':
			Toast('เครื่องลงทะเบียนเต็มแล้ว')
			break;
		case 'D0018':
			Toast('ลงทะเบียนจาก IP เกินขีดจำกัดในวันนี้แล้ว')
			break;
		case 'D0019':
			Toast('ชื่อผู้ใช้นี้มีคนใช้แล้ว')
			break;
		case 'D0020':
			Toast('ลงทะเบียนไม่สำเร็จ')
			break;
		case 'D0021':
			Toast('รหัสผ่านไม่ถูกต้อง')
			break;
		case 'D0022':
			Toast('เหรียญทองไม่พอ')
			break;
		case 'D0023':
			Toast('ยังไม่ได้ผูกบัตร')
			break;
		case 'D0024':
			Toast('บัตรนี้ไม่รองรับถอนเงิน กรุณาติดต่อฝ่ายบริการ')
			break;
		case 'D0025':
			Toast('ถอนเงินผิดปกติ ยังไม่ได้ตั้งค่าพารามิเตอร์')
			break;
		case 'D0026':
			Toast('ยังไม่ถึงจำนวนเงินขั้นต่ำในการถอน')
			break;
		case 'D0027':
			Toast('ไม่ตรงตามเงื่อนไขถอนเงิน')
			break;
		case 'D0028':
			Toast('คะแนน VIP ไม่ถูกต้อง')
			break;
		case 'D0029':
			Toast('รหัสภาพยืนยันผิด')
			break;
		case 'D0030':
			Toast('โค้ดของขวัญไม่ถูกต้อง')
			break;
		case 'D0031':
			Toast('โค้ดของขวัญถูกใช้แล้ว')
			break;
		case 'D0032':
			Toast('โค้ดของขวัญประเภทเดียวกันถูกใช้แล้ว')
			break;
		case 'D0033':
			Toast('โค้ดของขวัญชุดเดียวกันถูกใช้แล้ว')
			break;
		case 'D0034':
			Toast('ไม่สามารถผูกหมายเลขโทรศัพท์ได้')
			break;
		case 'D0035':
			Toast('โทเค็นไม่ถูกต้อง')
			break;
		case 'D0036':
			Toast('บัตรธนาคารนี้ผูกแล้ว')
			break;
		case 'D0037':
			Toast('ถึงขีดจำกัดผูกบัตรธนาคาร')
			break;
		case 'D0038':
			Toast('ผู้ใช้ผูกกับรหัสตู้นิรภัยแล้ว')
			break;
		case 'D0039':
			Toast('ผู้ใช้ยังไม่ได้ผูกกับรหัสตู้นิรภัย')
			break;
		case 'D0040':
			Toast('อีเมลนี้ถูกผูกกับผู้ใช้อื่นแล้ว')
			break;
		case 'D0041':
			Toast('อีเมลนี้ถูกผูกแล้ว')
			break;
		case 'D0042':
			Toast('ไม่สามารถส่งรหัสยืนยันได้')
			break;
		case 'D0043':
			Toast('ไม่พบเกมนี้')
			break;
		case 'D0045':
			Toast('รหัสผ่านเก่าไม่ถูกต้อง')
			break;
		default:
	}
}