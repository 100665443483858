//获取浏览器信息
// function getBrowserType() {
//   var ua = navigator.userAgent;
//   if (ua.indexOf('Firefox') != -1) {
//     return 'Firefox';
//   } else if (ua.indexOf('Opera') != -1 || ua.indexOf('OPR') != -1) {
//     return 'Opera';
//   } else if (ua.indexOf('Trident') != -1) {
//     return 'IE';
//   } else if (ua.indexOf('Edge') != -1) {
//     return 'Edge';
//   } else if (ua.indexOf('Chrome') != -1) {
//     return 'Chrome';
//   } else if (ua.indexOf('Safari') != -1) {
//     return 'Safari';
//   } else {
//     return 'unknown';
//   }
// }


function getBrowserInfo() {
  var ua = navigator.userAgent;
  var name, version;
  
  if (ua.indexOf('Firefox') != -1) {
    name = 'Firefox';
    version = ua.split('Firefox/')[1].split(' ')[0];
  } else if (ua.indexOf('Opera') != -1 || ua.indexOf('OPR') != -1) {
    name = 'Opera';
    version = ua.split('Opera|OPR/')[1].split(' ')[0];
  } else if (ua.indexOf('MSIE') != -1) {
    name = 'Internet Explorer';
    version = ua.split('MSIE ')[1].split(';')[0];
  } else if (ua.indexOf('Chrome') != -1) {
    name = 'Chrome';
    version = ua.split('Chrome/')[1].split(' ')[0].replace(/[^0-9.]/g, '');
  } else if (ua.indexOf('Safari') != -1) {
    name = 'Safari';
    version = ua.split('Version/')[1].split(' ')[0];
  } else {
    name = 'unknown';
    version = '0.0';
  }
  
  return {
    name: name,
    version: version
  };
}
function timestampToSeconds(timestamp) {

  return Math.floor(timestamp);
}
export function setKey() {
  const browserInfo = getBrowserInfo();
  localStorage.setItem("ua", browserInfo.name);
  localStorage.setItem("version", browserInfo.name+browserInfo.version);
  let lastExternalReferrerTime = timestampToSeconds(Date.now())
  localStorage.setItem("lastExternalReferrerTime", lastExternalReferrerTime);
  let key = browserInfo.name + '_' + lastExternalReferrerTime
  localStorage.setItem("key", key);
}

export function download(){
  window.open('https://www.t69.vip/', '_blank');
}


export function getCookie(name) {
  let cookies = document.cookie.split('; ');
  for (let i = 0; i < cookies.length; i++) {
    let parts = cookies[i].split('=');
    if (parts[0] === name) {
      return parts[1];
    }
  }

  return '';
}

export function getUrlSearch(name){
  // 未传参，返回空
  if (!name) return null;
  // 查询参数：先通过search取值，如果取不到就通过hash来取
  var after = window.location.search;
  after = after.substr(1) || window.location.hash.split('?')[1];
  // 地址栏URL没有查询参数，返回空
  if (!after) return null;
  // 如果查询参数中没有"name"，返回空
  if (after.indexOf(name) === -1) return null;
 
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  // 当地址栏参数存在中文时，需要解码，不然会乱码
  var r = decodeURI(after).match(reg);
  // 如果url中"name"没有值，返回空
  if (!r) return null;
 
  return r[2];
}