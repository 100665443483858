import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: HomeView,
    children:[
      {
        path: '/',
        meta:{
          activeNum:0
        },
        name: 'game',
        component: () => import('../views/game/index.vue')
      },
      {
        path: '/customer',
        meta:{
          activeNum:2
        },
        name: 'customer',
        component: () => import('../views/customer/index.vue')
      },
      {
        path: '/my',
        meta:{
          activeNum:3
        },
        name: 'my',
        component: () => import('../views/my/index.vue')
      },
      
      {
        path: '/recharge',
        meta:{
          activeNum:1
        },
        name: 'recharge',
        component: () => import('../views/recharge/index.vue')
      }
    ]
  },
  {
    path: '/card',
    name: 'card',
    component: () => import('../views/my/card.vue')
  },
  {
    path: '/noticeList',
    name: 'noticeList',
    component: () => import('../views/my/noticeList.vue')
  },
  {
    path: '/noticeDetails',
    name: 'noticeDetails',
    component: () => import('../views/my/noticeDetails.vue')
  },
  {
    path: '/gameRecord',
    name: 'gameRecord',
    component: () => import('../views/my/gameRecord.vue')
  },
  {
    path: '/rechargeRecords',
    name: 'rechargeRecords',
    component: () => import('../views/my/rechargeRecords.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/forgotPwd',
    name: 'forgotPwd',
    component: () => import('../views/login/forgotPwd.vue')
  },
  {
    path: '/forgotPwds',
    name: 'forgotPwds',
    component: () => import('../views/login/forgotPwds.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/login/register.vue')
  },
  {
    path: '/bindPhone',
    name: 'bindPhone',
    component: () => import('../views/my/bindPhone.vue')
  },
  {
    path: '/giftVouchers',
    name: 'giftVouchers',
    component: () => import('../views/my/giftVouchers.vue')
  },
  {
    path: '/gameApp',
    name: 'gameApp',
    component: () => import('../views/game/gameApp.vue')
  },
  {
    path: '/gameList',
    name: 'gameList',
    component: () => import('../views/game/gameList.vue')
  },
  {
    path: '/changePwa',
    name: 'changePwa',
    component: () => import('../views/my/changePwa.vue')
  },
  {
    path: '/withdrawal',
    name: 'withdrawal',
    component: () => import('../views/my/withdrawal.vue')
  }
]

const router = new VueRouter({
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  mode:'history',
  base:'/',
  routes
})

export default router
