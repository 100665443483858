<template>
    <div>
        <noscript><img id="myImage" height="1" width="1" style="display:none" :src="src" /></noscript>
    </div>
</template>

<script>
import { getCookie } from '../../utils/utils'
export default {
    data() {
        return {
            src:''
        }
    },
    created() {
        const urlSearch = window.location.search
        let urlArr = []
        urlArr = urlSearch.split('?')
        for (let i in urlArr) {
            urlArr[i] = urlArr[i].split('%3F')
        }
        let pppp = ''
        let rrrr = ''
        for (let i in urlArr) {
            for (let j in urlArr[i]) {
                if (urlArr[i][j].includes('p0')) {
                    pppp = urlArr[i][j]
                } else {
                    rrrr = urlArr[i][j]
                }
            }
        }

        let rrrrArr = rrrr.split('&')
        let pixelId
        let packageName
        let telegramId
        let bindTelegram
        let firstName
        let lastname
        let authDate
        let hash
        let cookiePixelId=getCookie('pixelId')
        let cookiePackageName=getCookie('packageName')
        console.log('22233')
        if(rrrr){
            for(let i in rrrrArr){
                if(rrrrArr[i].indexOf('pixelId=')!=-1){
                    pixelId = rrrrArr[i].replace('pixelId=', '')
                }else{
                    pixelId='1707422486492467'
                }
                if(rrrrArr[i].indexOf('packageName=')!=-1){
                    packageName = rrrrArr[i].replace('packageName=', '')
                }else{
                    packageName='com.th69.h5'
                }
                if(rrrrArr[i].indexOf('telegramId=')!=-1){
                    telegramId = rrrrArr[i].replace('telegramId=', '')
                }
                if(rrrrArr[i].indexOf('bindTelegram=')!=-1){
                    bindTelegram = rrrrArr[i].replace('bindTelegram=', '')
                }
                if(rrrrArr[i].indexOf('firstName=')!=-1){
                    firstName = rrrrArr[i].replace('firstName=', '')
                }
                if(rrrrArr[i].indexOf('lastname=')!=-1){
                    lastname = rrrrArr[i].replace('lastname=', '')
                }
                if(rrrrArr[i].indexOf('authDate=')!=-1){
                    authDate = rrrrArr[i].replace('authDate=', '')
                }
                if(rrrrArr[i].indexOf('hash=')!=-1){
                    hash = rrrrArr[i].replace('hash=', '')
                }
                
            }
             
             document.cookie='telegramId='+telegramId
             document.cookie='bindTelegram='+bindTelegram
             document.cookie='firstName='+firstName
             document.cookie='lastname='+lastname
             document.cookie='authDate='+authDate
             document.cookie='hash='+hash
             document.cookie='pixelId='+pixelId
             document.cookie='packageName='+packageName
        }else{
            document.cookie='packageName=com.th69.h5'
            document.cookie='pixelId=1707422486492467'
           
            
        }
        
  
        


        !function (f, b, e, v, n, t, s) {

            if (f.fbq) return; n = f.fbq = function () {
                n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            };
            if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
            n.queue = []; t = b.createElement(e); t.async = !0;
            t.src = v; s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
            'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', pixelId);
        fbq('track', 'PageView');

        this.src = "https://www.facebook.com/tr?id=" + pixelId + "&ev=PageView&noscript=1";
    }
}

</script>