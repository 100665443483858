// (function() {
//     var newRem = function() {
//       var html = document.documentElement;
//       html.style.fontSize = html.getBoundingClientRect().width / 375 + "px";
//       alert(html.getBoundingClientRect().width / 375 + "px")
//     };
//     window.addEventListener("resize", newRem, false);
//     newRem();
//   })();

(function (doc, win) {
  var docEl = doc.documentElement,
      resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
      recalc = function () {
          var clientWidth = docEl.clientWidth;
          if (!clientWidth) return;
          if (clientWidth >= 750) {
            docEl.style.fontSize = 10 * (clientWidth / 375) + 'px';
          } else {
              docEl.style.fontSize = 10 * (clientWidth / 375) + 'px';
          }
      };
  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window);
